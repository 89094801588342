<template>
  <div>
    <b-col class="p-0">
      <b-card-actions
        ref="refFilter"
        :title="$t('Filters')"
        action-collapse
        :collapsed="collapsedFilter"
      >
        <b-card-body class="p-0">

          <b-row>
            <!-- Field: ProductType -->
            <b-col
              cols="12"
              md="3"
              class="p-0 px-75 py-25"
            >
              <label>{{ $t('FormProduct.ProductType') }}</label>
              <v-select
                :value="productTypeFilter"
                :options="productTypeOptions"
                :clearable="false"
                class="w-100"
                :reduce="val => val.value"
                label="name"
                @input="(val) => $emit('update:productTypeFilter', val)"
              />
            </b-col>
            <!-- Field: ProductCategory -->
            <b-col
              cols="12"
              md="3"
              class="p-0 px-75 py-25"
            >
              <label>{{ $t('FormProduct.ProductCategory') }}</label>
              <v-select
                :value="productCategoryFilter"
                :options="productCategoryOptions"
                :clearable="false"
                class="w-100"
                :reduce="val => val.value"
                label="name"
                @input="(val) => $emit('update:productCategoryFilter', val)"
              />
            </b-col>
            <!-- Field: Product -->
            <b-col
              cols="12"
              md="3"
              class="p-0 px-75 py-25"
            >
              <label>{{ $t('FormProduct.Product') }}</label>
              <v-select
                :value="productFilter"
                :options="productOptions"
                :clearable="false"
                class="w-100"
                :reduce="val => val.value"
                label="name"
                @input="(val) => $emit('update:productFilter', val)"
              />
            </b-col>
            <!-- Field: BookingDate -->
            <b-col
              cols="12"
              md="3"
              class="p-0 px-75 py-25"
            >
              <label>{{ $t('FormBooking.BookingDate') }}</label>
              <el-date-picker
                :value="bookingDateFilter"
                type="daterange"
                :start-placeholder="$t('Start Date')"
                :end-placeholder="$t('End Date')"
                unlink-panels
                format="dd-MM-yyyy"
                value-format="yyyy-MM-dd"
                class="w-100 form-control"
                :picker-options="bookingDateOptions"
                @input="(val) => $emit('update:bookingDateFilter', val)"
              />
            </b-col>
          </b-row>
          <b-row>
            <!-- Booking Code -->
            <b-col
              cols="12"
              md="3"
              class="p-0 px-75 py-25"
            >
              <label>{{ $t('FormBooking.BookingCode') }}</label>
              <b-form-input
                :value="bookingCodeFilter"
                class="d-inline-block mr-1"
                :reduce="val => val.value"
                @input="(val) => $emit('update:bookingCodeFilter', val)"
              />
            </b-col>
            <!-- Name -->
            <b-col
              cols="12"
              md="3"
              class="p-0 px-75 py-25"
            >
              <label>{{ $t('FormProduct.ProductName') }}</label>
              <b-form-input
                :value="nameFilter"
                class="d-inline-block mr-1"
                :reduce="val => val.value"
                @input="(val) => $emit('update:nameFilter', val)"
              />
            </b-col>
            <!-- Status -->
            <b-col
              cols="12"
              md="3"
              class="p-0 px-75 py-25"
            >
              <label>{{ $t('FormBooking.Status') }}</label>
              <div class="d-flex">
                <v-select
                  :value="statusFilter"
                  :options="statusOptions"
                  class="w-100"
                  :clearable="true"
                  :reduce="val => val.value"
                  multiple
                  chips
                  @input="(val) => $emit('update:statusFilter', val)"
                />
              </div>
            </b-col>
            <!-- Payment Status -->
            <b-col
              cols="12"
              md="3"
              class="p-0 px-75 py-25"
            >
              <label>{{ $t('FormBooking.PaymentStatus') }}</label>
              <div class="d-flex">
                <v-select
                  :value="paymentStatusFilter"
                  :options="paymentStatusOptions"
                  class="w-100"
                  :clearable="true"
                  :reduce="val => val.value"
                  multiple
                  chips
                  @input="(val) => $emit('update:paymentStatusFilter', val)"
                />
              </div>
            </b-col>
          </b-row>

          <b-row class="mt-50">
            <!-- Button -->
            <b-col
              cols="12"
              md="12"
              class="p-0 px-75 py-25"
              style="text-align: right;"
            >
              <div>
                <b-button
                  variant="outline-primary"
                  @click="resetFilter"
                >
                  {{ `${$t('Reset Filter')}` }}
                </b-button>
                &nbsp;
                <b-button
                  variant="info"
                  @click="onCollapsed"
                >
                  {{ `${$t('Collapse')}` }}
                </b-button>
              </div>
            </b-col>
          </b-row>

        </b-card-body>
      </b-card-actions>
    </b-col>
  </div>
</template>

<script>
import {
  BCardBody, BRow, BCol,
  BFormInput,
  BButton,
} from 'bootstrap-vue'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import vSelect from 'vue-select'
import moment from 'moment'

export default {
  components: {
    BRow,
    BCol,
    BCardBody,
    BCardActions,
    BFormInput,
    BButton,
    vSelect,
  },
  props: {
    collapsedFilter: {
      type: Boolean,
      default: false,
    },
    productTypeFilter: {
      type: String,
      default: '',
    },
    productCategoryFilter: {
      type: String,
      default: '',
    },
    productFilter: {
      type: String,
      default: '',
    },
    bookingCodeFilter: {
      type: String,
      default: '',
    },
    nameFilter: {
      type: String,
      default: '',
    },
    statusFilter: {
      type: Array,
      default: () => [],
    },
    paymentStatusFilter: {
      type: Array,
      default: () => [],
    },
    bookingDateFilter: {
      type: Array,
      default: () => [],
    },
    productTypeOptions: {
      type: Array,
      default: () => [],
    },
    productCategoryOptions: {
      type: Array,
      default: () => [],
    },
    productOptions: {
      type: Array,
      default: () => [],
    },
    statusOptions: {
      type: Array,
      default: () => [],
    },
    paymentStatusOptions: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      bookingDateOptions: {
        shortcuts: [
          {
            text: this.$i18n.t('Today'),
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              picker.$emit('pick', [start, end])
            },
          },
          // {
          //   text: this.$i18n.t('Tomorrow'),
          //   onClick(picker) {
          //     const nextDate = moment().add('1', 'days')
          //     const start = new Date(nextDate.format('YYYY-MM-DD'))
          //     const end = new Date(nextDate.format('YYYY-MM-DD'))
          //     picker.$emit('pick', [start, end])
          //   },
          // },
          {
            text: this.$i18n.t('This week'),
            onClick(picker) {
              const currentDate = moment()
              const weekStart = currentDate.clone().startOf('week')
              const weekEnd = currentDate.clone().endOf('week')
              const start = new Date(weekStart.format('YYYY-MM-DD'))
              const end = new Date(weekEnd.format('YYYY-MM-DD'))
              picker.$emit('pick', [start, end])
            },
          },
          {
            text: this.$i18n.t('Next week'),
            onClick(picker) {
              const nextWeek = moment().add('1', 'weeks')
              const weekStart = nextWeek.clone().startOf('week')
              const weekEnd = nextWeek.clone().endOf('week')
              const start = new Date(weekStart.format('YYYY-MM-DD'))
              const end = new Date(weekEnd.format('YYYY-MM-DD'))
              picker.$emit('pick', [start, end])
            },
          },
          {
            text: this.$i18n.t('Last week'),
            onClick(picker) {
              const nextWeek = moment().subtract('1', 'weeks')
              const weekStart = nextWeek.clone().startOf('week')
              const weekEnd = nextWeek.clone().endOf('week')
              const start = new Date(weekStart.format('YYYY-MM-DD'))
              const end = new Date(weekEnd.format('YYYY-MM-DD'))
              picker.$emit('pick', [start, end])
            },
          },
          {
            text: this.$i18n.t('This month'),
            onClick(picker) {
              const currentMonth = moment()
              const monthStart = currentMonth.clone().startOf('month')
              const monthEnd = currentMonth.clone().endOf('month')
              const start = new Date(monthStart.format('YYYY-MM-DD'))
              const end = new Date(monthEnd.format('YYYY-MM-DD'))
              picker.$emit('pick', [start, end])
            },
          },
          {
            text: this.$i18n.t('Next month'),
            onClick(picker) {
              const nextMonth = moment().add('1', 'months')
              const monthStart = nextMonth.clone().startOf('month')
              const monthEnd = nextMonth.clone().endOf('month')
              const start = new Date(monthStart.format('YYYY-MM-DD'))
              const end = new Date(monthEnd.format('YYYY-MM-DD'))
              picker.$emit('pick', [start, end])
            },
          },
          {
            text: this.$i18n.t('Last month'),
            onClick(picker) {
              const lastMonth = moment().subtract('1', 'months')
              const monthStart = lastMonth.clone().startOf('month')
              const monthEnd = lastMonth.clone().endOf('month')
              const start = new Date(monthStart.format('YYYY-MM-DD'))
              const end = new Date(monthEnd.format('YYYY-MM-DD'))
              picker.$emit('pick', [start, end])
            },
          },
          {
            text: this.$i18n.t('Last 3 months'),
            onClick(picker) {
              const lastThreeMonth = moment().subtract('3', 'months')
              const lastMonth = moment().subtract('1', 'months')
              const monthStart = lastThreeMonth.clone().startOf('month')
              const monthEnd = lastMonth.clone().endOf('month')
              const start = new Date(monthStart.format('YYYY-MM-DD'))
              const end = new Date(monthEnd.format('YYYY-MM-DD'))
              picker.$emit('pick', [start, end])
            },
          },
          {
            text: this.$i18n.t('This year'),
            onClick(picker) {
              const currentYear = moment()
              const yearStart = currentYear.clone().startOf('year')
              const yearEnd = currentYear.clone().endOf('year')
              const start = new Date(yearStart.format('YYYY-MM-DD'))
              const end = new Date(yearEnd.format('YYYY-MM-DD'))
              picker.$emit('pick', [start, end])
            },
          },
          {
            text: this.$i18n.t('Last year'),
            onClick(picker) {
              const lastYear = moment().subtract('1', 'year')
              const yearStart = lastYear.clone().startOf('year')
              const yearEnd = lastYear.clone().endOf('year')
              const start = new Date(yearStart.format('YYYY-MM-DD'))
              const end = new Date(yearEnd.format('YYYY-MM-DD'))
              picker.$emit('pick', [start, end])
            },
          },
          {
            text: this.$i18n.t('Next year'),
            onClick(picker) {
              const nextYear = moment().add('1', 'year')
              const yearStart = nextYear.clone().startOf('year')
              const yearEnd = nextYear.clone().endOf('year')
              const start = new Date(yearStart.format('YYYY-MM-DD'))
              const end = new Date(yearEnd.format('YYYY-MM-DD'))
              picker.$emit('pick', [start, end])
            },
          },
        ],
      },
    }
  },
  methods: {
    resetFilter() {
      this.$emit('reset-filter')
    },
    onCollapsed() {
      this.$refs.refFilter.triggerCollapse()
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

.card {
  .card-title {
    font-size: 1.07rem;
  }
  .card-header {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
}
</style>
