import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    getDefaultBooking(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/booking/get_default_booking', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getListBooking(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/booking/get_list_booking', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getBooking(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/booking/get_booking', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addBooking(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/booking/add_booking', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    editBooking(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/booking/edit_booking', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteBooking(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/booking/delete_booking', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getListBookingSelect(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/booking/get_list_booking_select', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getCalendarBooking(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/booking/get_calendar_booking', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getTimeBooking(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/booking/get_time_booking', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getSummaryBooking(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/booking/get_summary_booking', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    bookingReport(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/booking/booking_report', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
