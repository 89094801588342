import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { t, t2 } from '@/@core/libs/i18n/utils'
import { $themeConfig } from '@themeConfig'

export default function tableBookingList() {
  // Use toast
  const toast = useToast()

  const refBookingListTable = ref(null)
  const lineOa = computed(() => store.state.lineapi.lineOa)

  const msgList = {
    errorfetch: t2('Error fetching {module} list', { module: t('TitleBooking.Booking') }),
    id: `${t('FormBooking.LineUser')} » ${t('ID')} » ${t('FormBooking.Status')} » ${t('FormBooking.Remark')}`,
    booking: `${t('FormBooking.Booking')}`,
    user: `${t('FormBooking.BookingUser')} » ${t('FormBooking.Approver')} » ${t('FormBooking.Other')}`,
    actions: t('Actions'),
  }

  // Table Handlers
  const tableColumns = [
    { key: 'id', sortable: true, label: msgList.id },
    { key: 'booking', sortable: true, label: msgList.booking },
    { key: 'user', sortable: false, label: msgList.user },
    { key: 'actions', label: msgList.actions },
  ]

  const optionAll = [{
    name: t('All'),
    value: '0',
  }]

  const perPage = ref(10)
  const totalBookings = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [2, 5, 10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)
  const locale = ref('th')

  const productTypeFilter = ref('0')
  const productCategoryFilter = ref('0')
  const productFilter = ref('0')
  const bookingCodeFilter = ref('')
  const nameFilter = ref('')
  const statusFilter = ref([])
  const paymentStatusFilter = ref([])
  const bookingDateFilter = ref([])
  const productTypeOptions = ref(optionAll)
  const productCategoryOptions = ref(optionAll)
  const productOptions = ref(optionAll)

  const dataMeta = computed(() => {
    const localItemsCount = refBookingListTable.value ? refBookingListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalBookings.value,
    }
  })

  const refetchData = () => {
    refBookingListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery, locale, productFilter, bookingCodeFilter, bookingDateFilter, nameFilter, statusFilter, paymentStatusFilter], () => {
    refetchData()
  })

  const getListBooking = (ctx, callback) => {
    const { id } = JSON.parse(lineOa.value)
    const params = {
      q: searchQuery.value,
      perPage: perPage.value,
      page: currentPage.value,
      sortBy: sortBy.value,
      sortDesc: isSortDirDesc.value,
      language: locale.value,
      bookingCode: bookingCodeFilter.value,
      name: nameFilter.value,
      status: statusFilter.value,
      paymentStatus: paymentStatusFilter.value,
      productType: productTypeFilter.value,
      productCategory: productCategoryFilter.value,
      product: productFilter.value,
      bookingDate: bookingDateFilter.value,
      lineOaId: id,
      viewLayout: 'list',
    }
    store
      .dispatch('store-booking/getListBooking', params)
      .then(response => {
        const { list, total } = response.data
        callback(list)
        totalBookings.value = total
        localStorage.setItem(`${$themeConfig.app.session}-filter-booking-list`, JSON.stringify(params))
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: msgList.errorfetch,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        },
        {
          position: 'bottom-right',
        })
      })
  }

  const getProductTypeOptions = () => {
    const params = {
      language: locale.value,
    }
    store
      .dispatch('store-product-type/getProductTypeOptions', params)
      .then(response => {
        productTypeOptions.value = [
          ...optionAll,
          ...response.data,
        ]
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: msgList.errorfetch,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        },
        {
          position: 'bottom-right',
        })
      })
  }

  const getProductCategoryOptions = () => {
    const params = {
      language: locale.value,
      productType: productTypeFilter.value,
    }
    store
      .dispatch('store-product-category/getProductCategoryOptions', params)
      .then(response => {
        productCategoryOptions.value = [
          ...optionAll,
          ...response.data,
        ]
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: msgList.errorfetch,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        },
        {
          position: 'bottom-right',
        })
      })
  }

  const getProductOptions = () => {
    const params = {
      language: locale.value,
      productType: productTypeFilter.value,
      productCategory: productCategoryFilter.value,
    }
    store
      .dispatch('store-product/getProductOptions', params)
      .then(response => {
        productOptions.value = [
          ...optionAll,
          ...response.data,
        ]
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: msgList.errorfetch,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        },
        {
          position: 'bottom-right',
        })
      })
  }

  watch([productTypeFilter], () => {
    if (productTypeFilter.value !== '' && productTypeFilter.value !== '0') {
      productCategoryFilter.value = '0'
      productFilter.value = '0'
      getProductCategoryOptions()
    } else {
      productCategoryOptions.value = optionAll
      productOptions.value = optionAll
      productCategoryFilter.value = '0'
      productFilter.value = '0'
    }
    refetchData()
  })
  watch([productCategoryFilter], () => {
    if (productCategoryFilter.value !== '' && productCategoryFilter.value !== '0') {
      productFilter.value = '0'
      getProductOptions()
    } else {
      productOptions.value = optionAll
      productFilter.value = '0'
    }
    refetchData()
  })

  const statusOptions = [
    {
      label: t('StatusMessage.all'),
      value: 'all',
    },
    {
      label: t('StatusMessage.wait_approve'),
      value: 'wait_approve',
    },
    {
      label: t('StatusMessage.approved'),
      value: 'approved',
    },
    {
      label: t('StatusMessage.disapprove'),
      value: 'disapprove',
    },
    {
      label: t('StatusMessage.canceled'),
      value: 'canceled',
    },
    {
      label: t('StatusMessage.wait_payment'),
      value: 'wait_payment',
    },
    {
      label: t('StatusMessage.paymented'),
      value: 'paymented',
    },
    {
      label: t('StatusMessage.wait_usage'),
      value: 'wait_usage',
    },
    {
      label: t('StatusMessage.usage_start'),
      value: 'usage_start',
    },
    {
      label: t('StatusMessage.usage_end'),
      value: 'usage_end',
    },
    {
      label: t('StatusMessage.complete'),
      value: 'complete',
    },
  ]

  const isStatusVariant = status => {
    if (status === 'created') return 'info'
    if (status === 'booking') return 'info'
    if (status === 'wait_usage') return 'info'
    if (status === 'usage_start') return 'warning'
    if (status === 'usage_end') return 'success'
    if (status === 'wait_approve') return 'warning'
    if (status === 'disapprove') return 'danger'
    if (status === 'disapproved') return 'danger'
    if (status === 'canceled') return 'danger'
    if (status === 'approved') return 'success'
    if (status === 'complete') return 'success'
    if (status === 'paymented') return 'success'
    return 'secondary'
  }

  const isStatusToText = isstatus => {
    if (isstatus === 'active') return 'Active'
    if (isstatus === 'inactive') return 'Inactive'
    return ''
  }
  const textFirstUpper = text => text.slice(0, 1).toUpperCase() + text.slice(1, text.length)

  const numberFormat = value => parseFloat(value).toFixed(2)

  const paymentStatusOptions = [
    {
      label: t('StatusMessage.all'),
      value: 'all',
    },
    {
      label: t('StatusMessage.wait_payment'),
      value: 'wait_payment',
    },
    {
      label: t('StatusMessage.canceled'),
      value: 'canceled',
    },
    {
      label: t('StatusMessage.paymented'),
      value: 'paymented',
    },
  ]

  return {
    getListBooking,
    tableColumns,
    perPage,
    currentPage,
    totalBookings,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    locale,
    refBookingListTable,

    refetchData,
    isStatusVariant,
    isStatusToText,
    textFirstUpper,
    numberFormat,

    bookingCodeFilter,
    nameFilter,
    statusFilter,
    paymentStatusFilter,

    statusOptions,
    paymentStatusOptions,

    productTypeFilter,
    productCategoryFilter,
    productFilter,
    bookingDateFilter,

    getProductTypeOptions,
    productTypeOptions,
    getProductCategoryOptions,
    productCategoryOptions,
    getProductOptions,
    productOptions,
  }
}
