<template>
  <b-modal
    id="product-select"
    :visible="showProductSelectModal"
    :title="`${$t('Select')} ${$t('FormProduct.Product')}`"
    footer-class="d-flex justify-content-between"
    size="xl"
    centered
    hide-footer
    no-close-on-backdrop
    @change="(val) => $emit('update:show-product-select-modal', val)"
  >
    <b-overlay
      :show="showOverlay"
      rounded="sm"
    >
      <!-- Modal Header -->
      <template #modal-header>
        <h5 class="modal-title">
          {{ `${$t('Select')} ${$t('FormProduct.Product')}` }}
        </h5>
        <div class="modal-actions">
          <feather-icon
            icon="MinusIcon"
            class="cursor-pointer"
            @click="(busy === false) ? $emit('update:show-product-select-modal', false) : false"
          />
          <feather-icon
            icon="XIcon"
            class="ml-1 cursor-pointer"
            @click="(busy === false) ? onDiscard() : false"
          />
        </div>
      </template>

      <b-card
        class="p-0 m-0"
        style="margin-top: 0.8rem !important;"
      >
        <b-card-title style="margin-bottom: 0rem !important;">
          <div>
            <small
              v-if="productData.name !== undefined && productData.name !== ''"
            >{{ `${$t('Select')} ${$t('FormProduct.Product')}` }}</small>
            <b-alert
              v-if="productData.name !== undefined && productData.name !== ''"
              show
              variant="success"
              style="margin-top: 0.2rem;"
            >
              <div class="alert-body">
                <feather-icon
                  class="mr-25"
                  icon="CheckCircleIcon"
                />
                <span class="ml-25">{{ productData.name }}</span>
              </div>
            </b-alert>
          </div>
        </b-card-title>

        <div style="margin: 0rem 0rem 0rem 0rem;">
          <b-row>
            <b-col
              cols="12"
              sm="4"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
            >
              <span class="text-muted">
                {{ $t('Showing {from} to {to} of {of} entries',
                      {from: dataMeta.from, to: dataMeta.to, of: dataMeta.of })
                }}
              </span>
            </b-col>
            <b-col
              cols="12"
              sm="12"
              md="2"
            >
              <b-form-group
                :label="`${$t('FormProduct.ProductType')}`"
                label-for="product-type"
              >
                <v-select
                  id="product-type"
                  v-model="productFilter.product_type"
                  :options="productTypeOptions"
                  class="w-100"
                  :clearable="false"
                  :reduce="val => val.value"
                />
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              sm="12"
              md="3"
            >
              <b-form-group
                :label="`${$t('FormProduct.Category')}`"
                label-for="category"
              >
                <v-select
                  id="category"
                  v-model="productFilter.category"
                  :options="categoryOptions"
                  class="w-100"
                  :clearable="false"
                  :reduce="val => val.value"
                />
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              sm="12"
              md="3"
            >
              <b-form-group
                :label="`${$t('Search')}`"
                label-for="search"
              >
                <b-form-input
                  id="search"
                  v-model="productFilter.q"
                  class="d-inline-block m-0"
                  :placeholder="`${$t('Search')}...`"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </div>

        <div
          class="pb-75 pl-75 pr-75 pt-75"
          style="border: 1px solid #cccccc; background-color: #eeeeee; border-radius: 0.5rem;"
        >
          <b-row>
            <b-col
              v-for="tableCol in tableColumns"
              :key="tableCol.key"
              role="columnheader"
              :cols="tableCol.col"
            >
              <div>
                {{ tableCol.label }}
              </div>
            </b-col>
          </b-row>
        </div>
        <VuePerfectScrollbar
          class="scroll-area"
          :settings="settings"
          @ps-scroll-y="scrollHandle"
        >
          <b-row
            v-for="item in productList"
            :key="item.id"
            class="mt-1 mb-1 mr-1"
          >
            <b-col cols="4">
              <div style="margin-top: 0.3rem;">
                <b-list-group>
                  <!-- Product Type -->
                  <b-list-group-item
                    style="padding: 0.4rem 0.6rem 0.2rem 0.6rem;"
                  >
                    <span style="font-size: 14px; font-weight: bold;">
                      {{ $t('FormProduct.ProductType') }} »
                    </span>
                    <span
                      v-if="item.information.product_type"
                      style="font-size: 14px;"
                    >
                      {{ `${item.information.product_type.name} (${item.information.product_type.code})` }}
                    </span>
                  </b-list-group-item>
                  <!-- Category -->
                  <b-list-group-item
                    style="padding: 0.4rem 0.6rem 0.2rem 0.6rem;"
                  >
                    <span style="font-size: 14px; font-weight: bold;">
                      {{ $t('FormProduct.Category') }} »
                    </span>
                    <span style="font-size: 14px;">{{ item.information.category.name }}</span>
                  </b-list-group-item>
                  <!-- Department -->
                  <b-list-group-item
                    style="padding: 0.4rem 0.6rem 0.2rem 0.6rem;"
                  >
                    <span style="font-size: 14px; font-weight: bold;">
                      {{ $t('FormProduct.Department') }} »
                    </span>
                    <span style="font-size: 14px;">{{ item.information.department.name }}</span>
                  </b-list-group-item>
                </b-list-group>
              </div>
            </b-col>

            <b-col cols="6">
              <!-- Product -->
              <div style="margin-top: 0.3rem;">
                <b-list-group>
                  <!-- Code -->
                  <b-list-group-item
                    style="padding: 0.4rem 0.6rem 0.2rem 0.6rem;"
                  >
                    <span style="font-size: 14px; font-weight: bold;">
                      {{ $t('FormProduct.Code') }} »
                    </span>
                    <span style="font-size: 14px;">
                      <b-badge
                        pill
                        variant="light-success"
                        class="px-1"
                      >
                        {{ item.information.code }}
                      </b-badge>
                    </span>
                  </b-list-group-item>
                  <!-- Name -->
                  <b-list-group-item
                    style="padding: 0.4rem 0.6rem 0.2rem 0.6rem;"
                  >
                    <span style="font-size: 14px; font-weight: bold;">
                      {{ $t('FormProduct.Name') }} »
                    </span>
                    <span style="font-size: 14px;">
                      {{ item.information.name }}
                    </span>
                  </b-list-group-item>
                </b-list-group>
              </div>
              <!-- Use with -->
              <div style="margin-top: 0.3rem;">
                <b-list-group>
                  <b-list-group-item
                    style="padding: 0.6rem 0.6rem 0.4rem 0.6rem;"
                  >
                    <span style="font-size: 14px; margin-right: 0.6rem; font-weight: bold;">
                      {{ $t('FormProduct.UseWith') }} »
                    </span>
                    <span
                      v-if="item.information.is_booking === true"
                      style="font-size: 14px;"
                    >
                      <b-badge
                        pill
                        variant="light-warning"
                        class="px-1"
                      >
                        {{ (item.information.is_booking === true)? $t('FormProduct.IsBooking') : '' }}
                      </b-badge>
                    </span>
                    <span
                      v-if="item.information.is_renting === true"
                      style="font-size: 14px;"
                      class="ml-50"
                    >
                      <b-badge
                        pill
                        variant="light-info"
                        class="px-1"
                      >
                        {{ (item.information.is_renting === true)? $t('FormProduct.IsRenting') : '' }}
                      </b-badge>
                    </span>
                    <span
                      v-if="item.information.is_for_staff === true"
                      style="font-size: 14px;"
                      class="ml-50"
                    >
                      <b-badge
                        pill
                        variant="light-success"
                        class="px-1"
                      >
                        {{ (item.information.is_for_staff === true)? $t('FormProduct.IsForStaff') : '' }}
                      </b-badge>
                    </span>
                  </b-list-group-item>
                </b-list-group>
              </div>
            </b-col>

            <b-col cols="2">

              <div
                style="margin-top: 0.3rem;"
              >
                <b-button
                  v-if="checkSelect(item)"
                  size="sm"
                  variant="primary"
                  style="margin-left: 0rem;"
                  @click.stop="onSelect(item)"
                >
                  <feather-icon
                    icon="CheckCircleIcon"
                    size="14"
                    style="margin-right: 0.4rem;"
                  />
                  <span class="align-middle">{{ $t('Select') }}</span>
                </b-button>

                <b-button
                  v-else
                  size="sm"
                  variant="outline-success"
                  style="margin-left: 0rem;"
                >
                  <feather-icon
                    icon="CheckCircleIcon"
                    size="14"
                    style="margin-right: 0.4rem;"
                  />
                  <span class="align-middle">{{ $t('Selected') }}</span>
                </b-button>
              </div>
            </b-col>
          </b-row>
        </VuePerfectScrollbar>
      </b-card>
    </b-overlay>
  </b-modal>
</template>

<script>
import {
  BButton,
  BCard,
  BCardTitle,
  BRow,
  BCol,
  BAlert,
  BListGroup,
  BListGroupItem,
  BBadge,
  BFormInput,
  BFormGroup,

  BOverlay,
} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import {
  onUnmounted,
} from '@vue/composition-api'
import store from '@/store'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import vSelect from 'vue-select'
import storeProduct from './storeProduct'

export default {
  components: {
    BButton,
    BCard,
    BCardTitle,
    BRow,
    BCol,
    BAlert,
    BListGroup,
    BListGroupItem,
    BBadge,
    BFormInput,
    BOverlay,
    BFormGroup,

    VuePerfectScrollbar,
    vSelect,
  },
  model: {
    prop: 'showProductSelectModal',
    event: 'update:show-product-select-modal',
  },
  props: {
    showProductSelectModal: {
      type: Boolean,
      required: true,
    },
    productData: {
      type: Object,
      default: () => {},
    },
    productFilter: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      showOverlay: false,
      busy: false,
      skin: store.state.appConfig.layout.skin,
      settings: {
        maxScrollbarLength: 60,
      },
      perPage: 100,
      totalItem: 0,
      currentPage: 1,
      sortBy: 'type_code',
      isSortDirDesc: true,
      locale: 'th',
      productList: [],
      tableColumns: [],
    }
  },
  computed: {
    dataMeta() {
      const localItemsCount = this.productList.length
      return {
        from: (localItemsCount ? 1 : 0),
        to: localItemsCount,
        of: this.totalItem,
      }
    },
  },
  watch: {
    'productFilter.q': {
      handler() {
        this.refetchData()
      },
    },
    'productFilter.product_type': {
      handler() {
        this.refetchData()
      },
    },
    'productFilter.category': {
      handler() {
        this.refetchData()
      },
    },
    showProductSelectModal(value) {
      if (value === true) {
        this.productList = []
        this.currentPage = 1
        this.getListProductSelect()
      }
    },
  },
  mounted() {
    const msgList = {
      type: `${this.$i18n.t('FormProduct.ProductType')} » ${this.$i18n.t('FormProduct.Category')} » ${this.$i18n.t('FormProduct.Department')}`,
      product: `${this.$i18n.t('FormProduct.Code')} » ${this.$i18n.t('FormProduct.ProductName')}  » ${this.$i18n.t('FormProduct.UseWith')}`,
      actions: this.$i18n.t('Actions'),
    }
    this.tableColumns = [
      {
        key: 'type', sortable: true, label: msgList.type, col: 4,
      },
      {
        key: 'product', sortable: true, label: msgList.product, col: 6,
      },
      { key: 'actions', label: msgList.actions, col: 2 },
    ]
  },
  methods: {
    getListProductSelect() {
      this.showOverlay = true
      const params = {
        productId: this.productData.id,
        perPage: this.perPage,
        page: this.currentPage,
        sortBy: this.sortBy,
        sortDesc: this.isSortDirDesc,
        language: this.locale,
        q: this.productFilter.q,
        product_type: this.productFilter.product_type,
        category: this.productFilter.category,
      }
      store
        .dispatch('store-product-select/getListProductSelect', params)
        .then(response => {
          const { list, total } = response.data
          if (this.currentPage === 1) {
            // console.log('first')
            this.productList = list
            if (response.data.product_type_options !== undefined) {
              this.productTypeOptions = response.data.product_type_options
            }
            if (response.data.product_category_options !== undefined) {
              this.categoryOptions = response.data.product_category_options
            }
          } else {
            // append
            // console.log('append')
            list.forEach(ll => {
              this.productList.push(ll)
            })
            // console.log(this.productList)
          }
          this.totalItem = total
          this.showOverlay = false
        })
        .catch(error => {
          const { response, message } = error
          if (response) {
            const errorMsg = this.$i18n.t(response?.data.error)
            this.$toast({
              component: ToastificationContent,
              props: {
                title: errorMsg,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            },
            {
              position: 'bottom-right',
            })
          } else if (message) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: error?.message,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            },
            {
              position: 'bottom-right',
            })
          }
          this.showOverlay = false
        })
    },
    refetchData() {
      this.productList = []
      this.currentPage = 1
      this.getListProductSelect()
    },
    scrollHandle(event) {
      // console.log(event)

      // console.log(event.target.scrollTop)
      // console.log(event.target.clientHeight)
      // console.log(event.target.scrollHeight)
      if (event.target.scrollTop + event.target.clientHeight >= (event.target.scrollHeight - 2)) {
        // console.log('last scroll')
        if (this.showOverlay === false) {
          const totalListItem = this.productList.length
          // console.log(totalListItem)
          if (this.totalItem > totalListItem) {
            this.currentPage += 1
            // console.log('load more')
            this.getListProductSelect()
          }
        }
      }
    },
    onSelect(item) {
      this.busy = true
      this.$emit('update-product-select', { select: item, product: this.productData })
      this.$emit('update:show-product-select-modal', false)
      this.busy = false
    },
    checkSelect(item) {
      if (parseInt(item.information.id, 10) === parseInt(this.productData.id, 10)) {
        return false
      }
      return true
    },
  },
  setup() {
    if (!store.hasModule('store-product-select')) store.registerModule('store-product-select', storeProduct)
    onUnmounted(() => {
      if (store.hasModule('store-product-select')) store.unregisterModule('store-product-select')
    })
    const productTypeOptions = []
    const categoryOptions = []
    return { productTypeOptions, categoryOptions }
  },
}
</script>

<style>
.scroll-area {
  position: relative;
  margin: auto;
  width: 100%;
  height: 50vh;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
